import { handleCallApi } from "@/helpers/api-method";
import ProfileService from "@/services/profile.service";
export const state = {
    profile: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_PROFILE(state, value) {
        state.profile = value;
    }
};

export const actions = {
    async getProfile({ commit }, query) {
        await handleCallApi(() => ProfileService.getProfile(query), (value) => commit('CHANGE_PROFILE', value));
    }
};