import {
    API_LIST_ROLE,
    API_CREATE_ROLE,
    API_UPDATE_ROLE,
    API_DELETE_ROLE,
    API_DETAIL_ROLE
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class User {
    async getRoles(data) {
        const result = await Fetch.make().get(API_LIST_ROLE, { authHeader: true, query: data });
        return result;
    }
    async createRole(data) {
        const result = await Fetch.make().post(API_CREATE_ROLE, data, { authHeader: true });
        return result;
    }
    async updateRole(data) {
        const url = API_UPDATE_ROLE.replace(":id", data.id);
        const result = await Fetch.make().put(url, data, { authHeader: true });
        return result;
    }
    async deleteRole(data) {
        const url = API_DELETE_ROLE.replace(":id", data.id);
        const result = await Fetch.make().delete(url, { authHeader: true });
        return result;
    }
    async getRoleDetail(data) {
        const url = API_DETAIL_ROLE.replace(":id", data.id);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }
}

export default new User();
