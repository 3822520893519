
import { API_ACCOUNTS, API_CREATE_ACCOUNT, API_UPDATE_ACCOUNT,
    API_DETELE_ACCOUNT,
    API_DETAIL_ACCOUNT, API_FANPAGE_ACCOUNT, API_CREATE_ACCOUNT_FACEBOOK } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class AssistantService {
    async getAccounts(query) {
        const result = await Fetch.make().get(API_ACCOUNTS, {authHeader: true, query})
        return result
    }
    async createAccount(payload) {
        const result = await Fetch.make().post(API_CREATE_ACCOUNT, payload, {authHeader: true})
        return result
    }

    async updateAccount (payload) {
        const url = API_UPDATE_ACCOUNT.replace(":id", payload.id)
        const result = await Fetch.make().put(url, payload, {authHeader: true})
        return result
    }
    async deleteAccount (id) {
        const url = API_DETELE_ACCOUNT.replace(":id", id)
        const result = await Fetch.make().delete(url, {authHeader: true})
        return result
    }

    async getDetailAccount (id) {
        const url = API_DETAIL_ACCOUNT.replace(":id", id)
        const result = await Fetch.make().get(url, {authHeader: true})
        return result
    }

    async getFanPage(id) {
        const url = API_FANPAGE_ACCOUNT.replace(":id", id)
        const result = await Fetch.make().get(url, {authHeader: true})
        return result
    }

    async createAccountByFacebook(payload) {
        const url = API_CREATE_ACCOUNT_FACEBOOK
        const result = await Fetch.make().post(url, payload, {authHeader: true})
        return result
    }
    
}

export default new AssistantService();