import { API_AIS, API_CREATE_AI,API_UPDATE_ASSISTANT,
    API_DETELE_ASSISTANT, API_DETAIL_ASSISTANT} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class AssistantService {
    async getAIs(query) {
        const result = await Fetch.make().get(API_AIS, {authHeader: true, query})
        return result
    }
    async createAI(payload) {
        const result = await Fetch.make().post(API_CREATE_AI, payload, {authHeader: true})
        return result
    }

    async updateAssistant (payload) {
        const url = API_UPDATE_ASSISTANT.replace(":id", payload.id)
        const result = await Fetch.make().put(url, payload, {authHeader: true})
        return result
    }

    async deleteAssistant (id) {
        const url = API_DETELE_ASSISTANT.replace(":id", id)
        const result = await Fetch.make().delete(url, {authHeader: true})
        return result
    }

    async getDetailAssistant (id) {
        const url = API_DETAIL_ASSISTANT.replace(":id", id)
        const result = await Fetch.make().get(url, {authHeader: true})
        return result
    }
}

export default new AssistantService();