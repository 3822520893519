<script setup>
import TwoColumns from "@/layouts/twocolumn";
import Horizontal from '@/layouts/horizontal.vue';
import { computed , onMounted, ref} from "vue";
import { useStore } from "vuex";
const layout = {
    "horizontal": Horizontal,
    "twocolumn": TwoColumns
}
const store = useStore()

const layoutType = computed(() => store.state.layout.layoutType)
const loadingData = ref(false)
onMounted(async() => {
	await store.dispatch('auth/getPermissions')
    await store.dispatch('profile/getProfile')
    loadingData.value = true
})
</script>

<template>
    <div>
        <component :is="layout[layoutType]" v-if="loadingData">
            <div>
                <slot />
            </div>
        </component>
        <div v-else class="fixed top-0 right-0 left-0 botton-0 d-flex align-items-center justify-content-center">
            <div id="preloader">
                <div id="status">
                    <div class="spinner-border text-primary avatar-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-else class="p-4">
            Đang chuẩn bị dữ liệu...
        </div> -->
    </div>
</template>
