import { NOTIFICATIONS,NOTIFICATIONS_READ, NOTIFICATIONS_UNREAD } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Notification {

    async getNotifications(query) {
        const result = await Fetch.make().get(NOTIFICATIONS, {authHeader: true, query})
        return result
    }

    async readNotification(id) {
        const result = await Fetch.make().post(NOTIFICATIONS_READ, {id}, {authHeader: true})
        return result
    }

    async getUnRead() {
        const result = await Fetch.make().get(NOTIFICATIONS_UNREAD, {authHeader: true})
        return result
    }
}

export default new Notification();