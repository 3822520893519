import { API_CATEGORIES, API_CATEGORY_CREATE } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Category {
    async getCategories() {
        const result = await Fetch.make().get(API_CATEGORIES, {authHeader: true})
        return result
    }

    async createCategory(payload) {
        const result = await Fetch.make().post(API_CATEGORY_CREATE, payload, {authHeader: true})
        return result
    }
}

export default new Category();