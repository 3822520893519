<script>
export default {

}
</script>

<template>
    <div>
        <slot>

        </slot>
    </div>
</template>

<style lang="scss" scoped></style>