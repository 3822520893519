import { API_USERS } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class User {

    async getUsers() {
        const result = await Fetch.make().get(API_USERS, {authHeader: true})
        return result
    }
}

export default new User();