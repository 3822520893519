import { handleCallApi } from "@/helpers/api-method";
import ContractService from "@/services/contract.service";
export const state = {
    contracts: {
        status: '',
        data: [],
        message: '',
    },

    contactDetail: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {
    CHANGE_CONTRACTS(state, value) {
        state.contracts = value
    },
    CHANGE_CONTACT_DETAIL(state, value) {
        state.contactDetail = value
    },
};

export const actions = {
    async getContracts({ commit }, query) {
        handleCallApi(() => ContractService.getContracts(query), (value) => commit('CHANGE_CONTRACTS', value));
    },

    async getContract({ commit }, id) {
        handleCallApi(() => ContractService.getContract(id), (value) => commit('CHANGE_CONTACT_DETAIL', value));
    },

};