
import { API_LIST_PERMISSION } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Permission {

    async getPermissions() {
        const result = await Fetch.make().get(API_LIST_PERMISSION, {authHeader: true})
        return result
    }
}

export default new Permission();