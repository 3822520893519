import _isEmpty from "lodash/isEmpty";

export const handleCallApi = async (callback, handleState) => {
    handleState({
        status: "pending",
        message: "",
        data: {},
    });
    var result = {};
    try {
        result = await callback();
    } catch (error) {
        handleState({
            code: result?.code,
            status: "error",
            message: 'Có lỗi trong quá trình kết nối đến Server',
            data: {},
        });
        console.error('api-respone >> catch >> ',error);
        return result;
    }

    if (_isEmpty(result)) {
        handleState({
            code: result?.code,
            status: "error",
            message: JSON.stringify(result?.message),
            data: {},
        });
        return result;
    }

    if ("error" == result?.status) {
        handleState({
            code: result?.code,
            status: "error",
            message: _isEmpty(result?.data) ? result?.message : handleMessageError(result?.data),
            data: {},
        });
        console.error('api-respone >> ', result?.data);
        return result;
    }

    if (result?.data) {
        handleState({
            code: result?.code,
            status: "success",
            message: result?.message,
            data: result?.data,
        });
        return result;
    }

    handleState({
        status: "error",
        message: "Server bảo trì tính năng",
        data: {},
    });
    console.error("api-respone >> ", result);
    return result;
};

const handleMessageError = (error) => {
    let message = [];
    for (let key in error) {
        //eslint-disable-next-line
        if (error.hasOwnProperty(key)) {
            message = message.concat(error[key]);
        }
    }
    return message.join("\n");
};
