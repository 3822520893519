import { handleCallApi } from "@/helpers/api-method";
import CategoryService from "@/services/category.service";
export const state = {
    categories: {
        status: '',
        data: [],
        message: '',
    },

    createCategory: {
        status: '',
        data: [],
        message: '',
    },

    createSubCategory: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {

    CHANGE_CATEGORIES(state, value) {
        state.categories.data = value.data;
        state.categories.status = value.status;
        state.categories.message = value.message;
    },

    CHANGE_CREATE_CATEGORY(state, value) {
        state.createCategory.data = value.data;
        state.createCategory.status = value.status;
        state.createCategory.message = value.message;
    },

    CHANGE_CREATE_SUB_CATEGORY(state, value) {
        state.createSubCategory.data = value.data;
        state.createSubCategory.status = value.status;
        state.createSubCategory.message = value.message;
    },
    
};

export const actions = {
    async getCategories({ commit }) {
        await handleCallApi(CategoryService.getCategories, (value) => commit('CHANGE_CATEGORIES', value));
    },

    async createCategory({ commit }, data) {    
        await handleCallApi(() => CategoryService.createCategory(data), (value) => commit('CHANGE_CREATE_CATEGORY', value));
    },
    async createSubCategory({ commit }, data) {    
        await handleCallApi(() => CategoryService.createCategory(data), (value) => commit('CHANGE_CREATE_SUB_CATEGORY', value));
    }
};