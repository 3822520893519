import { handleCallApi } from "@/helpers/api-method";
import IndustryService from "@/services/industries.service";
export const state = {
    industries: {
        status: '',
        data: [],
        message: '',
    },

    createIndustry: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {
    CHANGE_INDUSTRIES(state, value) {
        state.industries = value;
    },
    CHANGE_CREATE_INDUSTRY(state, value) {
        state.createIndustry = value;
    }
};

export const actions = {
    async getIndustries({ commit }, query) {
        await handleCallApi(() => IndustryService.getIndustries(query), (value) => commit('CHANGE_INDUSTRIES', value));
    },

    async createIndustry({ commit }, data) {
        await handleCallApi(() => IndustryService.createIndustry(data), (value) => commit('CHANGE_CREATE_INDUSTRY', value));
    }
};