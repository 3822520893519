import { handleCallApi } from "@/helpers/api-method";
import PlanningDetailService from "@/services/planning-detail.service";
export const state = {
    listTopic : {
        status: '',
        data: [],
        message: '',
    },
    createTopic : {
        status: '',
        data: [],
        message: '',
    },
    updateTopic : {
        status: '',
        data: [],
        message: '',
    },
    deleteTopic : {
        status: '',
        data: [],
        message: '',
    },

    uploadFileExcel : {
        status: '',
        data: [],
        message: '',
    },

    detailTopic: {
        status: '',
        data: [],
        message: '',
    },
    uploadLink: {
        status: '',
        data: [],
        message: '',
    },

    assigned: {
        status: '',
        data: [],
        message: '',
    },

    startWritingPost: {
        status: '',
        data: [],
        message: '',
    },

    lock: {
        status: '',
        data: [],
        message: '',
    },

    unlock: {
        status: '',
        data: [],
        message: '',
    },

    requestReview: {
        status: '',
        message: '',
        data: {},
    },
    denyReview: {
        status: '',
        message: '',
        data: {},
    },
    requestApprove: {
        status: '',
        message: '',
        data: {},
    },
    denyApprove: {
        status: '',
        message: '',
        data: {},
    },
    approve: {
        status: '',
        message: '',
        data: {},
    },
    spreadsheetSheet: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    
    CHANGE_listTopic(state, value) {
        state.listTopic = value
    },
    CHANGE_createTopic(state, value) {
        state.createTopic = value
    },
    CHANGE_updateTopic(state, value) {
        state.updateTopic = value
    },
    CHANGE_deleteTopic(state, value) {
        state.deleteTopic = value
    },

    CHANGE_uploadFileExcel(state, value) {
        state.uploadFileExcel = value
    },
   
    CHANGE_detailTopic(state, value) {
        state.detailTopic = value
    },

    CHANGE_uploadLink(state, value) {
        state.uploadLink = value
    },

    CHANGE_assigned(state, value) {
        state.assigned = value
    },

    CHANGE_startWritingPost(state, value) {
        state.startWritingPost = value
    },

    CHANGE_lock(state, value) { 
        state.lock = value
    },

    CHANGE_unlock(state, value) {
        state.unlock = value
    },

    CHANGE_requestReview(state, value) {
        state.requestReview = value
    },
    CHANGE_denyReview(state, value) {
        state.denyReview = value
    },
    CHANGE_requestApprove(state, value) {
        state.requestApprove = value
    },
    CHANGE_denyApprove(state, value) {
        state.denyApprove = value
    },
    CHANGE_approve(state, value) {
        state.approve = value
    },

    CHANGE_spreadsheetSheet(state, value) {
        state.spreadsheetSheet = value
    },
};

export const actions = {
    async getListTopic({ commit }, id) {
        await handleCallApi(() => PlanningDetailService.getListTopic(id), (value) => commit('CHANGE_listTopic', value));
    },

    async createTopic({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.createTopic(data), (value) => commit('CHANGE_createTopic', value));
    },
    async updateTopic({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.updateTopic(data), (value) => commit('CHANGE_updateTopic', value));
    },

    async deleteTopic({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.deleteTopic(data), (value) => commit('CHANGE_deleteTopic', value));
    },
    async uploadFileExcel({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.uploadFileExcel(data), (value) => commit('CHANGE_uploadFileExcel', value));
    },

    async getDetailTopic({ commit }, payload) {
        await handleCallApi(() => PlanningDetailService.getDetailTopic(payload), (value) => commit('CHANGE_detailTopic', value));
    },

    async uploadLink({ commit }, data) {
        await handleCallApi(() => PlanningDetailService.uploadLink(data), (value) => commit('CHANGE_uploadLink', value));
    },

    async assigned({ commit }, data) {
        await handleCallApi(() => PlanningDetailService.assigned(data), (value) => commit('CHANGE_assigned', value));
    },

    async startWritingPost({ commit }, data) {
        await handleCallApi(() => PlanningDetailService.startWritingPost(data), (value) => commit('CHANGE_startWritingPost', value));
    },

    async lock({ commit }, data) {
        await handleCallApi(() => PlanningDetailService.lock(data), (value) => commit('CHANGE_lock', value));
    },

    async unlock({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.unlock(data), (value) => commit('CHANGE_unlock', value));
    },

    async requestReview({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.requestReview(data), (value) => commit('CHANGE_requestReview', value));
    },
    async denyReview({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.denyReview(data), (value) => commit('CHANGE_denyReview', value));
    },
    async requestApprove({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.requestApprove(data), (value) => commit('CHANGE_requestApprove', value));
    },
    async denyApprove({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.denyApprove(data), (value) => commit('CHANGE_denyApprove', value));
    },
    async approve({ commit }, data) {    
        await handleCallApi(() => PlanningDetailService.approve(data), (value) => commit('CHANGE_approve', value));
    },

    async getSpreadsheetSheet({ commit }, payload) {
        await handleCallApi(() => PlanningDetailService.getSpreadsheetSheet(payload), (value) => commit('CHANGE_spreadsheetSheet', value));
    },

    reset({ commit }) {
        commit('CHANGE_spreadsheetSheet', {
            status: '',
            data: [],
            message: '',
        });
    },
};