import { handleCallApi } from "@/helpers/api-method";
import planningService from "@/services/planning.service";
export const state = {
    
    createPlanning: {
        status: '',
        data: [],
        message: '',
    },

    detail: {
        status: '',
        data: [],
        message: '',
    },

    plannings: {
        status: '',
        data: [],
        message: '',
    },

    updatePlanning: {
        status: '',
        data: [],
        message: '',
    },

    deletePlanning: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {
    CHANGE_CREATE_PLANNING(state, value) {
        state.createPlanning.data = value.data;
        state.createPlanning.status = value.status;
        state.createPlanning.message = value.message;
    },

    CHANGE_DETAIL(state, value) {
        state.detail = value
    },

    CHANGE_PLANNINGS(state, value) {
        state.plannings = value 
    },

    CHANGE_UPDATE_PLANNING(state, value) {
        state.updatePlanning = value
    },

    CHANGE_DELETE_PLANNING(state, value) {
        state.deletePlanning = value
    }
};

export const actions = {
    async createPlanning({ commit }, data) {
        handleCallApi(() => planningService.createPlanning(data), (value) => commit('CHANGE_CREATE_PLANNING', value));
    },

    async getPlanningDetail({ commit }, id) {
        handleCallApi(() => planningService.getPlanningDetail(id), (value) => commit('CHANGE_DETAIL', value));
    },

    async getPlannings({ commit }, query) {
        handleCallApi(() => planningService.getPlannings(query), (value) => commit('CHANGE_PLANNINGS', value));
    },

    async updatePlanning({ commit }, data) {
        handleCallApi(() => planningService.updatePlanning(data), (value) => commit('CHANGE_UPDATE_PLANNING', value));
    },

    async deletePlanning({ commit }, id) {
        handleCallApi(() => planningService.deletePlanning(id), (value) => commit('CHANGE_DELETE_PLANNING', value));
    }
    
};