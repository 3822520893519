import { handleCallApi } from "@/helpers/api-method";
import AccountService from "@/services/account.service";
export const state = {
    accounts: {
        status: "",
        data: [],
        message: "",
    },

    createAccount: {
        status: "",
        data: [],
        message: "",
    },

    updateAccount: {
        status: "",
        data: [],
        message: "",
    },

    deleteAccount: {
        status: "",
        data: [],
        message: "",
    },

    detailAccount: {
        status: "",
        data: [],
        message: "",
    },

    fanPage: {
        status: "",
        data: [],
        message: "",
    },

    createAccountByFacebook: {
        status: "",
        data: [],
        message: "",
    },
};

export const mutations = {
    CHANGE_ACCOUNTS(state, value) {
        state.accounts.data = value.data;
        state.accounts.status = value.status;
        state.accounts.message = value.message;
    },

    CHANGE_CREATE_ACCOUNT(state, value) {
        state.createAccount.data = value.data;
        state.createAccount.status = value.status;
        state.createAccount.message = value.message;
    },

    CHANGE_UPDATE_ACCOUNT(state, value) {
        state.updateAccount = value;
    },

    CHANGE_DELETE_ACCOUNT(state, value) {
        state.deleteAccount = value;
    },

    CHANGE_DETAIL_ACCOUNT(state, value) {
        state.detailAccount = value;
    },

    CHANGE_FAN_PAGE(state, value) {
        state.fanPage = value;
    },

    CHANGE_CREATE_ACCOUNT_BY_FACEBOOK(state, value) {
        state.createAccountByFacebook = value;
    },
};

export const actions = {
    async getAccounts({ commit }, query) {
        handleCallApi(
            () => AccountService.getAccounts(query),
            (value) => commit("CHANGE_ACCOUNTS", value)
        );
    },

    async createAccount({ commit }, data) {
        handleCallApi(
            () => AccountService.createAccount(data),
            (value) => commit("CHANGE_CREATE_ACCOUNT", value)
        );
    },

    async updateAccount({ commit }, data) {
        handleCallApi(
            () => AccountService.updateAccount(data),
            (value) => commit("CHANGE_UPDATE_ACCOUNT", value)
        );
    },

    async deleteAccount({ commit }, id) {
        handleCallApi(
            () => AccountService.deleteAccount(id),
            (value) => commit("CHANGE_DELETE_ACCOUNT", value)
        );
    },

    async getDetailAccount({ commit }, id) {
        handleCallApi(
            () => AccountService.getDetailAccount(id),
            (value) => commit("CHANGE_DETAIL_ACCOUNT", value)
        );
    },

    async getFanPage({ commit }, id) {
        handleCallApi(
            () => AccountService.getFanPage(id),
            (value) => commit("CHANGE_FAN_PAGE", value)
        );
    },

    async createAccountByFacebook({ commit }, data) {
        handleCallApi(
            () => AccountService.createAccountByFacebook(data),
            (value) => commit("CHANGE_CREATE_ACCOUNT_BY_FACEBOOK", value)
        );
    },
};
