import { handleCallApi } from "@/helpers/api-method";
import MemberService from "@/services/member.service";
export const state = {
    members: {
        status: '',
        data: [],
        message: '',
    },

    updateMember: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {
    CHANGE_MEMBERS(state, value) {
        state.members = value;
    },

    CHANGE_UPDATE_MEMBER(state, value) {
        state.updateMember = value;
    }
};

export const actions = {
    async getMembers({ commit }, query) {
        await handleCallApi(() => MemberService.getMembers(query), (value) => commit('CHANGE_MEMBERS', value));
    },

    async updateMember({ commit }, data) {
        await handleCallApi(() => MemberService.updateMember(data), (value) => commit('CHANGE_UPDATE_MEMBER', value));
    }
};