import {
    API_GET_LIST_TOPIC,
    API_CREATE_TOPIC,
    API_UPDATE_TOPIC,
    API_DETELE_TOPIC,
    API_UPLOAD_FILE_PLAN,
    API_DETAIL_TOPIC,
    API_UPLOAD_LINK_FILE_PLAN,
    API_GET_LIST_TOPIC_DASHBOARD,
    API_ASSIGNED,
    API_STARTWRITINGPOST,
    API_LOCK_TASK,
    API_UNLOCK_TASK,
    API_REQUEST_REVIEW,
    API_DENY_REVIEW,
    API_REQUEST_APPPROVE,
    API_DENY_APPROVE,
    API_APPROVE,
    API_PUBLISH_POST,
    API_GET_SPREADSHEET_SHEET,
    API_GET_TASK_CUSTOMER_INFO
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class PlanningDetailService {
    async getListTopic(id) {
        const url = API_GET_LIST_TOPIC.replace(":id", id);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }
    async createTopic(payload) {
        const url = API_CREATE_TOPIC.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async updateTopic(payload) {
        var url = API_UPDATE_TOPIC.replace(":id", payload.planId);
        url = url.replace(":detailId", payload.topicId);
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async deleteTopic(payload) {
        var url = API_DETELE_TOPIC.replace(":id", payload.planId);
        url = url.replace(":detailId", payload.topicId);
        const result = await Fetch.make().delete(url, { authHeader: true });
        return result;
    }
    async uploadFileExcel(payload) {
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("ADSBASE-TOKEN")
        );
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload.data,
            redirect: "follow",
        };

        const url = API_UPLOAD_FILE_PLAN.replace(":id", payload.id);

        const result = await fetch(url, requestOptions);
        const data = await result.json();
        return data;
    }

    async getDetailTopic(payload) {
        var url = API_DETAIL_TOPIC.replace(":id", payload.planId);
        url = url.replace(":detailId", payload.topicId);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }

    async uploadLink(payload) {
        var url = API_UPLOAD_LINK_FILE_PLAN.replace(":id", payload.planId);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }

    async getListTopicDashboard(query) {
        const result = await Fetch.make().get(API_GET_LIST_TOPIC_DASHBOARD, {
            authHeader: true,
            query,
        });
        return result;
    }

    async assigned(payload) {
        const url = API_ASSIGNED;
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }

    async startWritingPost(payload) {
        const url = API_STARTWRITINGPOST.replace(":id", payload);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }

    async lock(payload) {
        const url = API_LOCK_TASK.replace(":id", payload);
        const result = await Fetch.make().put(url, {}, { authHeader: true });
        return result;
    }

    async unlock(payload) {
        const url = API_UNLOCK_TASK.replace(":id", payload);
        const result = await Fetch.make().put(url, {}, { authHeader: true });
        return result;
    }

    async requestReview(payload) {
        const url = API_REQUEST_REVIEW.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async denyReview(payload) {
        const url = API_DENY_REVIEW.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async requestApprove(payload) {
        const url = API_REQUEST_APPPROVE.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async denyApprove(payload) {
        const url = API_DENY_APPROVE.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async approve(payload) {
        const url = API_APPROVE.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async publish(payload) {
        const url = API_PUBLISH_POST.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }

    async updateCalendarPublish(payload) {
        const url = API_PUBLISH_POST.replace(":id", payload.id);
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }

    async updatePublishPost(id) {
        const url = API_PUBLISH_POST.replace(":id", id)
        const result = await Fetch.make().put(url, {}, {
            authHeader: true,
        });
        return result;
    }

    async getSpreadsheetSheet(payload) {
        const url = API_GET_SPREADSHEET_SHEET.replace(":id", payload.id);
        const result = await Fetch.make().post(url, payload, {
            authHeader: true,
        });
        return result;
    }

    async getCustomerTaskInfo(id) {
        const url = API_GET_TASK_CUSTOMER_INFO.replace(":id", id);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }
}

export default new PlanningDetailService();
