import {
    API_PROMPT_CREATE,
    API_GET_LIST_PROMPT,
    API_DELETE_PROMPT,
    API_UNDO_PROMPT,
    API_GET_DETAIL_PROMPT,
    API_UPDATE_PROMPT,
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Prompt {
    async createPrompt(payload) {
        const result = await Fetch.make().post(API_PROMPT_CREATE, payload, {
            authHeader: true,
        });
        return result;
    }

    async getListPrompt(query) {
        const result = await Fetch.make().get(API_GET_LIST_PROMPT, {
            authHeader: true,
            query,
        });
        return result;
    }

    async getDeletePrompt(payload) {
        const result = await Fetch.make().put(API_DELETE_PROMPT, {ids: payload}, {
            authHeader: true,
        });
        return result;
    }

    async getUndoPrompt(payload) {
        const result = await Fetch.make().put(API_UNDO_PROMPT, {ids: payload}, {
            authHeader: true,
        });
        return result;
    }

    async getDetailPrompt(id) {
        const url = API_GET_DETAIL_PROMPT.replace(':id', id)
        const result = await Fetch.make().get(url, {
            authHeader: true,
        })
        return result;
    }

    async getUpdatePrompt(id,payload) {
        const url = API_UPDATE_PROMPT.replace(':id', id)
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }
}

export default new Prompt();
