import { handleCallApi } from "@/helpers/api-method";
import PromptService from "@/services/prompt.service";
export const state = {
    createPrompt: {
        status: '',
        data: [],
        message: '',
    },
    
    listPrompt: {
        status: '',
        data: [],
        message: '',
    },

    deletePrompt: {
        status: '',
        data: [],
        message: '',
    },

    undoPrompt: {
        status: '',
        data: [],
        message: '',
    },

    detailPrompt: {
        status: '',
        data: [],
        message: '',
    },

    updatePrompt: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_CREATE_PROMPT(state, value) {
        state.createPrompt.data = value.data;
        state.createPrompt.status = value.status;
        state.createPrompt.message = value.message;
    },

    CHANGE_LIST_PROMPT(state, value) {
        state.listPrompt.data = value.data;
        state.listPrompt.status = value.status;
        state.listPrompt.message = value.message;
    },

    CHANGE_DELETE_PROMPT(state, value) {
        state.deletePrompt.data = value.data;
        state.deletePrompt.status = value.status;
        state.deletePrompt.message = value.message;
    },

    CHANGE_UNDO_PROMPT(state, value) {
        state.undoPrompt.data = value.data;
        state.undoPrompt.status = value.status;
        state.undoPrompt.message = value.message;
    },

    CHANGE_DETAIL_PROMPT(state, value) {
        state.detailPrompt.data = value.data;
        state.detailPrompt.status = value.status;
        state.detailPrompt.message = value.message;
    },
    
    CHANGE_UPDATE_PROMPT(state, value) {
        state.updatePrompt.data = value.data;
        state.updatePrompt.status = value.status;
        state.updatePrompt.message = value.message;
    }
    
};

export const actions = {
    async createPrompt({ commit }, data) {    
        await handleCallApi(() => PromptService.createPrompt(data), (value) => commit('CHANGE_CREATE_PROMPT', value));
    },

    async getListPrompt({ commit }, query) {    
        await handleCallApi(() => PromptService.getListPrompt(query), (value) => commit('CHANGE_LIST_PROMPT', value));
    },

    async getDeletePrompt({ commit }, payload) {    
        await handleCallApi(() => PromptService.getDeletePrompt(payload), (value) => commit('CHANGE_DELETE_PROMPT', value));
    },

    async getUndoPrompt({ commit }, payload) {    
        await handleCallApi(() => PromptService.getUndoPrompt(payload), (value) => commit('CHANGE_UNDO_PROMPT', value));
    },

    async getDetailPrompt({ commit }, id) {    
        await handleCallApi(() => PromptService.getDetailPrompt(id), (value) => commit('CHANGE_DETAIL_PROMPT', value));
    },

    async getUpdatePrompt({ commit }, value) {    
        await handleCallApi(() => PromptService.getUpdatePrompt(value.id, value.payload), (value) => commit('CHANGE_UPDATE_PROMPT', value));
    },
};