import { handleCallApi } from "@/helpers/api-method";
import AssistantService from '@/services/assistant.service';
export const state = {
    ais: {
        status: '',
        data: [],
        message: '',
    },

    create_ai: {
        status: '',
        data: [],
        message: '',
    },

    updateAssistant: {
        status: '',
        data: [],
        message: '',
    },

    deleteAssistant: {
        status: '',
        data: [],
        message: '',
    },

    detailAssistant: {
        status: '',
        data: [],
        message: '',
    },
}

export const mutations = {
    CHANGE_AIS(state, value) {
        state.ais.data = value.data;
        state.ais.status = value.status;
        state.ais.message = value.message;
    },

    CHANGE_CREATE_AI(state, value) {
        state.create_ai.data = value.data;
        state.create_ai.status = value.status;
        state.create_ai.message = value.message;
    },

    CHANGE_UPDATE_ASSISTANT(state, value) {
        state.updateAssistant = value
    },

    CHANGE_DELETE_ASSISTANT(state, value) {
        state.deleteAssistant = value
    },

    CHANGE_DETAIL_ASSISTANT(state, value) {
        state.detailAssistant = value
    },
};

export const actions = {
    async getAIs({ commit }, query) {
        handleCallApi(() => AssistantService.getAIs(query), (value) => commit('CHANGE_AIS', value));
    },
    async createAI({ commit }, data) {
        handleCallApi(() => AssistantService.createAI(data), (value) => commit('CHANGE_CREATE_AI', value));
    },

    async updateAssistant({ commit }, data) {
        handleCallApi(() => AssistantService.updateAssistant(data), (value) => commit('CHANGE_UPDATE_ASSISTANT', value));
    },
    async deleteAssistant({ commit }, id) {
        handleCallApi(() => AssistantService.deleteAssistant(id), (value) => commit('CHANGE_DELETE_ASSISTANT', value));
    },

    async getDetailAssistant({ commit }, id) {
        handleCallApi(() => AssistantService.getDetailAssistant(id), (value) => commit('CHANGE_DETAIL_ASSISTANT', value));
    },
    
};