import {
    NOTIFICATIONS_INDUSTRY
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Industry {

    async getIndustries() {
        const result = await Fetch.make().get(NOTIFICATIONS_INDUSTRY, {
            authHeader: true,
        });
        return result;
    }

    async createIndustry(payload) {
        const result = await Fetch.make().post(NOTIFICATIONS_INDUSTRY, payload, {
            authHeader: true,
        });
        return result;
    }
}

export default new Industry();
