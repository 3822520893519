import { handleCallApi } from "@/helpers/api-method";
import authService from "@/services/auth.service";
import router from "@/router";
import _isEmpty from 'lodash/isEmpty'
import {safeJsonParse} from "@/helpers/utils";
export const state = {
    auth: {
        status: '',
        token:  localStorage.getItem('ADSBASE-TOKEN'),
        message: '',
    },
    
    permissions: {
        status: '',
        data: safeJsonParse(localStorage.getItem('ADSBASE-PERMISSIONS'), []) || [],
        message: '',
    },

    logout: {
        status: '',
        data: [],
        message: '',
    }
};

export const mutations = {
    CHANGE_AUTH(state, value) {
        state.auth.token = value.data.token;
        state.auth.status = value.status;
        state.auth.message = value.message;
        state.auth.code = value.code;
        localStorage.setItem('ADSBASE-TOKEN', state.auth.token);
    },

    CHANGE_PERMISSION(state, value) {
        state.permissions = value
        localStorage.setItem('ADSBASE-PERMISSIONS', JSON.stringify(state.permissions.data));
    },

    CHANGE_LOGOUT(state, value) {
        state.logout = value
    }
};

export const actions = {
    async loginGoogle({ commit }) {
        const result = await handleCallApi(authService.loginGoogle, (value) => commit('CHANGE_AUTH', value));
        if(_isEmpty(result) || result.status == 'error') {
            
            commit('CHANGE_AUTH', {status: result?.status, message: result?.message ?? 'Có lỗi trong quá trình đăng nhập.', code: result?.code, data: {}})
            return
        }

        if(result?.data)
        {
            router.push({name: 'loading'})
        }
    },

    async getPermissions({ commit }) {
        await handleCallApi(authService.getPermissions, (value) => commit('CHANGE_PERMISSION', value));
    },

    async logout({ commit }) {
        await handleCallApi(authService.logout, (value) => commit('CHANGE_LOGOUT', value));
    }
};