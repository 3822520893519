<script>
import {
  layoutComputed
} from "@/state/helpers";
import {
  SimpleBar
} from "simplebar-vue3";
import { sidebar_routes } from "@/config/sidebar-route";
import isEmpty from "lodash/isEmpty";
import get from 'lodash/get'
export default {
  components: {
    SimpleBar
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      sidebar_routes,
      permissions: this.$store.state.auth.permissions.data
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  // watch: {
  //   $route: {
  //     handler: "onRoutechange",
  //     immediate: true,
  //     deep: true,
  //   },
  // },

  // mounted() {
  //   if (document.querySelectorAll(".navbar-nav .collapse")) {
  //     let collapses = document.querySelectorAll(".navbar-nav .collapse");

  //     collapses.forEach((collapse) => {
  //       // Hide sibling collapses on `show.bs.collapse`
  //       collapse.addEventListener("show.bs.collapse", (e) => {
  //         e.stopPropagation();
  //         let closestCollapse = collapse.parentElement.closest(".collapse");
  //         if (closestCollapse) {
  //           let siblingCollapses =
  //             closestCollapse.querySelectorAll(".collapse");
  //           siblingCollapses.forEach((siblingCollapse) => {
  //             if (siblingCollapse.classList.contains("show")) {
  //               siblingCollapse.classList.remove("show");
  //               siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
  //             }
  //           });
  //         } else {
  //           let getSiblings = (elem) => {
  //             // Setup siblings array and get the first sibling
  //             let siblings = [];
  //             let sibling = elem.parentNode.firstChild;
  //             // Loop through each sibling and push to the array
  //             while (sibling) {
  //               if (sibling.nodeType === 1 && sibling !== elem) {
  //                 siblings.push(sibling);
  //               }
  //               sibling = sibling.nextSibling;
  //             }
  //             return siblings;
  //           };
  //           let siblings = getSiblings(collapse.parentElement);
  //           siblings.forEach((item) => {
  //             if (item.childNodes.length > 2) {
  //               item.firstElementChild.setAttribute("aria-expanded", "false");
  //               item.firstElementChild.classList.remove("active");
  //             }
  //             let ids = item.querySelectorAll("*[id]");
  //             ids.forEach((item1) => {
  //               item1.classList.remove("show");
  //               item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
  //               item1.parentElement.firstChild.classList.remove("active");
  //               if (item1.childNodes.length > 2) {
  //                 let val = item1.querySelectorAll("ul li a");

  //                 val.forEach((subitem) => {
  //                   if (subitem.hasAttribute("aria-expanded"))
  //                     subitem.setAttribute("aria-expanded", "false");
  //                 });
  //               }
  //             });
  //           });
  //         }
  //       });

  //       // Hide nested collapses on `hide.bs.collapse`
  //       collapse.addEventListener("hide.bs.collapse", (e) => {
  //         e.stopPropagation();
  //         let childCollapses = collapse.querySelectorAll(".collapse");
  //         childCollapses.forEach((childCollapse) => {
  //           let childCollapseInstance = childCollapse;
  //           childCollapseInstance.classList.remove("show");
  //           childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
  //         });
  //       });
  //     });
  //   }
  // },

  methods: {
    _get(obj, path, defaultValue = '') {
      return get(obj, path, defaultValue);
    },
    _isEmpty(obj) {
      return isEmpty(obj);
    },
    // onRoutechange(ele) {
    //   this.initActiveMenu(ele.path);
    //   if (document.getElementsByClassName("mm-active").length > 0) {
    //     const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
    //     if (currentPosition > 500)
    //       if (this.$refs.isSimplebar)
    //         this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
    //   }
    // },

    // initActiveMenu(ele) {
    //   setTimeout(() => {
    //     if (document.querySelector("#navbar-nav")) {
    //       let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
    //       if (a) {
    //         a.classList.add("active");
    //         let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
    //         if (parentCollapseDiv) {
    //           parentCollapseDiv.classList.add("show");
    //           parentCollapseDiv.parentElement.children[0].classList.add("active");
    //           parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
    //           if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
    //             parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
    //             if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
    //               parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
    //             const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
    //             if (grandparent && grandparent && grandparent.previousElementSibling) {
    //               grandparent.previousElementSibling.classList.add("active");
    //               grandparent.classList.add("show");
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }, 0);
    // },
  },
};
</script>

<template>
  <b-container fluid>
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li v-for="(item, index) in sidebar_routes" :key="index" class="nav-item py-2 px-4" :class="{
          'd-none': _isEmpty(_get(permissions, item.permission)) && _get(item, 'permission') !== 'public',
        }">
          <router-link v-if="_isEmpty(item.childrens)" class="d-flex gap-4 fs-12" :to="item.link"
            :class="$route.fullPath.includes(item.name) ? 'text-white' : 'text-muted'">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </router-link>
          <b-link v-if="!_isEmpty(item.childrens)" class="d-flex gap-4 fs-12" :href="`#${item.id}`"
            data-bs-toggle="collapse" role="button" :aria-expanded="false" :aria-controls="item.id"
            :class="$route.fullPath.includes(item.name) ? 'text-white' : 'text-muted'">
            <i :class="item.icon"></i>
            <span> {{ item.label }}</span>
          </b-link>
          <div v-if="!_isEmpty(item.childrens)" class="collapse menu-dropdown" :id="item.name">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item py-2" v-for="(child, index) in item.childrens" :key="index"
                :class="{
                  'd-none': _isEmpty(_get(permissions, child.permission)) && _get(child, 'permission') !== 'public',
                }"
              >
                <router-link :to="child.link" class="custom-abc"
                  :class="$route.fullPath == child.link ? 'text-white' : 'text-muted'">
                  - {{ child.label }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </SimpleBar>
    </template>
  </b-container>
</template>