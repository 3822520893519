import { handleCallApi } from "@/helpers/api-method";
import PermissionService from "@/services/permission.service";
export const state = {
    permissions: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_PERMISSIONS(state, value) {
        state.permissions = value;
    }
};

export const actions = {
    async getPermissions({ commit }, query) {
        await handleCallApi(() => PermissionService.getPermissions(query), (value) => commit('CHANGE_PERMISSIONS', value));
    }
};