<script>
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";
import { sidebar_routes } from "@/config/sidebar-route";
import isEmpty from "lodash/isEmpty";
import _get from 'lodash/get'
import {ScreenHelper} from "@/helpers/utils";
import CustomFooter from "@/components/ui/footer/custom-footer.vue";
export default {
    watch: {
        $route: {
            handler: "onRoutechange",
            immediate: true,
            deep: true,
        },
    },
    methods: {
        _get (obj, path, defaultValue = '') {
            return _get(obj, path, defaultValue)
        },
        _isEmpty(obj) {
            return isEmpty(obj);
        },
        // onRoutechange(ele) {
        //     this.initActiveMenu(ele.path);
        // },
        // initActiveMenu(ele) {
        //     setTimeout(() => {
        //         if (document.querySelector("#navbar-nav")) {
        //             let a = document
        //                 .querySelector("#navbar-nav")
        //                 .querySelector('[href="' + ele + '"]');

        //             if (a) {
        //                 a.classList.add("active");
        //                 let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
        //                 if (parentCollapseDiv) {
        //                     parentCollapseDiv.classList.add("show");
        //                     parentCollapseDiv.parentElement.children[0].classList.add(
        //                         "active"
        //                     );
        //                     parentCollapseDiv.parentElement.children[0].setAttribute(
        //                         "aria-expanded",
        //                         "true"
        //                     );
        //                     if (
        //                         parentCollapseDiv.parentElement.closest(
        //                             ".collapse.menu-dropdown"
        //                         )
        //                     ) {
        //                         parentCollapseDiv.parentElement
        //                             .closest(".collapse")
        //                             .classList.add("show");
        //                         if (
        //                             parentCollapseDiv.parentElement.closest(".collapse")
        //                                 .previousElementSibling
        //                         )
        //                             parentCollapseDiv.parentElement
        //                                 .closest(".collapse")
        //                                 .previousElementSibling.classList.add("active");
        //                     }
        //                 }
        //             }
        //         }
        //     }, 1000);
        // },
    },

    data() {
        return {
            sidebar_routes,
            mobile: false,
            permissions: this.$store.state.auth.permissions.data
        }
    },

    components: {
        NavBar,
        RightBar,
        Footer,
        CustomFooter
    },

    mounted() {
        ScreenHelper.initialize((isMobile) => {
            this.mobile = isMobile
        })
    }
};
</script>

<template>
    <div>
        <div id="layout-wrapper">
            <NavBar />
            <!-- ========== App Menu ========== -->
            <div class="app-menu navbar-menu">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <!-- Dark Logo-->
                    <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-dark.png" alt="" height="17" />
                        </span>
                    </router-link>
                    <!-- Light Logo-->
                    <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-light.png" alt="" height="17" />
                        </span>
                    </router-link>
                    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i class="ri-record-circle-line"></i>
                    </button>
                </div>
                <div id="scrollbar">
                    <b-container fluid>
                        <ul class="navbar-nav h-100" id="navbar-nav" @click.stop>
                            <li class="menu-title">
                                <span data-key="t-menu">{{ $t("t-menu") }}</span>
                            </li>
                            <!-- <li class="nav-item">
                                <b-link class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse"
                                    role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i class="las la-tachometer-alt"></i>
                                    <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
                                </b-link>
                                <div class="collapse menu-dropdown" id="sidebarDashboards">
                                    <ul class="nav nav-sm flex-column">
                                        <li class="nav-item">
                                            <router-link to="/dashboard/analytics" class="nav-link custom-abc"
                                                data-key="t-analytics">
                                                {{ $t("t-analytics") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/dashboard/crm" class="nav-link" data-key="t-crm">
                                                {{ $t("t-crm") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/" class="nav-link" data-key="t-ecommerce">
                                                {{ $t("t-ecommerce") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/dashboard/crypto" class="nav-link" data-key="t-crypto">
                                                {{ $t("t-crypto") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/dashboard/projects" class="nav-link"
                                                data-key="t-projects">
                                                {{ $t("t-projects") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/dashboard/nft" class="nav-link" data-key="t-nft">
                                                {{ $t("t-nft") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/dashboard/job" class="nav-link" data-key="t-job">
                                                {{ $t("t-job") }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li> -->

                            <li v-for="(item, index) in sidebar_routes" :key="index" class="nav-item"
                                :class="{
                                    'd-none': _isEmpty(_get(permissions, item.permission)) && _get(item, 'permission') !== 'public',
                                }"
                            >
                                <router-link class="d-flex p-3 gap-2 fs-12" :to="item.link" v-if="_isEmpty(item.childrens)"
                                    :class="$route.fullPath.includes(item.name) ? 'active text-primary' : 'text-muted'"
                                >
                                    <i :class="item.icon"></i>
                                    <span data-key="t-dashboards" class="text-nowrap">{{ item.label }}</span>
                                </router-link>

                                <b-link class="d-flex p-3 gap-2 fs-12" :href="`#${item.id}`" data-bs-toggle="collapse"
                                    role="button" aria-expanded="false" :aria-controls="item.id"
                                    :class="$route.fullPath.includes(item.name) ? 'active text-primary' : 'text-muted'"
                                    v-if="!_isEmpty(item.childrens)">
                                    <i :class="item.icon"></i>
                                    <span> {{ item.label }}</span>
                                </b-link>
                                <div class="collapse menu-dropdown" :id="item.id" v-if="!_isEmpty(item.childrens)">
                                    <ul class="nav nav-sm flex-column">
                                        <li class="nav-item" v-for="(sub, index) in item.childrens" :key="index">
                                            <router-link :to="sub.link" class=" d-flex px-3 py-2 gap-2 fs-12"
                                                v-if="!_isEmpty(_get(permissions, sub.permission)) || _get(sub, 'permission') == 'public'"
                                                :class="$route.fullPath==sub.link ? 'active text-primary' : 'text-muted'"
                                            >
                                                <i :class="sub.icon"></i>
                                                {{ sub.label }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <!-- end Dashboard Menu -->
                            <!-- <li class="nav-item">
                                <b-link class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse"
                                    role="button" aria-expanded="false" aria-controls="sidebarApps">
                                    <i class="lab la-delicious"></i>
                                    <span data-key="t-apps"> {{ $t("t-apps") }}</span>
                                </b-link>
                                <div class="collapse menu-dropdown" id="sidebarApps">
                                    <ul class="nav nav-sm flex-column">
                                        <li class="nav-item">
                                            <router-link to="/calendar" class="nav-link" data-key="t-calendar">
                                                {{ $t("t-calendar") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/chat" class="nav-link" data-key="t-chat">
                                                {{ $t("t-chat") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebaremail" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebaremail"
                                                data-key="t-projects">
                                                {{ $t("t-email") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebaremail">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/mailbox" class="nav-link"
                                                            data-key="t-mailbox">
                                                            {{ $t("t-mailbox") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <b-link href="#sidebarsubemail" class="nav-link"
                                                            data-bs-toggle="collapse" role="button"
                                                            aria-expanded="false" aria-controls="sidebarsubemail"
                                                            data-key="t-projects">
                                                            {{ $t("t-email-templates") }}
                                                        </b-link>
                                                        <div class="collapse menu-dropdown" id="sidebarsubemail">
                                                            <ul class="nav nav-sm flex-column">
                                                                <li class="nav-item">
                                                                    <router-link to="/email/email-basic"
                                                                        class="nav-link" data-key="t-products">
                                                                        {{ $t("t-basic-action") }}
                                                                    </router-link>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <router-link to="/email/email-ecommerce"
                                                                        class="nav-link" data-key="t-products">
                                                                        {{ $t("t-ecommerce-action") }}
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarEcommerce" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarEcommerce"
                                                data-key="t-ecommerce">
                                                {{ $t("t-ecommerce") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarEcommerce">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/products" class="nav-link"
                                                            data-key="t-products">
                                                            {{ $t("t-products") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/product-details" class="nav-link"
                                                            data-key="t-product-Details">
                                                            {{ $t("t-product-Details") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/add-product" class="nav-link"
                                                            data-key="t-create-product">
                                                            {{ $t("t-create-product") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/orders" class="nav-link"
                                                            data-key="t-orders">
                                                            {{ $t("t-orders") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/order-details" class="nav-link"
                                                            data-key="t-order-details">
                                                            {{ $t("t-order-details") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/customers" class="nav-link"
                                                            data-key="t-customers">
                                                            {{ $t("t-customers") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/shopping-cart" class="nav-link"
                                                            data-key="t-shopping-cart">
                                                            {{ $t("t-shopping-cart") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/checkout" class="nav-link"
                                                            data-key="t-checkout">
                                                            {{ $t("t-checkout") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/sellers" class="nav-link"
                                                            data-key="t-sellers">
                                                            {{ $t("t-sellers") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/ecommerce/seller-details" class="nav-link"
                                                            data-key="t-sellers-details">
                                                            {{ $t("t-sellers-details") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarProjects" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarProjects"
                                                data-key="t-projects">
                                                {{ $t("t-projects") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarProjects">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/apps/projects-list" class="nav-link"
                                                            data-key="t-list">
                                                            {{ $t("t-list") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/projects-overview" class="nav-link"
                                                            data-key="t-overview">
                                                            {{ $t("t-overview") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/projects-create" class="nav-link"
                                                            data-key="t-create-project">
                                                            {{ $t("t-create-project") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarTasks" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarTasks"
                                                data-key="t-tasks">
                                                {{ $t("t-tasks") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarTasks">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/apps/tasks-kanban" class="nav-link"
                                                            data-key="t-kanbanboard">
                                                            {{ $t("t-kanbanboard") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/tasks-list-view" class="nav-link"
                                                            data-key="t-list-view">
                                                            {{ $t("t-list-view") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/tasks-details" class="nav-link"
                                                            data-key="t-task-details">
                                                            {{ $t("t-task-details") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarCRM" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarCRM"
                                                data-key="t-crm">
                                                {{ $t("t-crm") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarCRM">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/apps/crm-contacts" class="nav-link"
                                                            data-key="t-contacts">
                                                            {{ $t("t-contacts") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/crm-companies" class="nav-link"
                                                            data-key="t-companies">
                                                            {{ $t("t-companies") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/crm-deals" class="nav-link"
                                                            data-key="t-deals">
                                                            {{ $t("t-deals") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/crm-leads" class="nav-link"
                                                            data-key="t-leads">
                                                            {{ $t("t-leads") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarCrypto" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarCrypto"
                                                data-key="t-crypto">
                                                {{ $t("t-crypto") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarCrypto">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/transactions" class="nav-link"
                                                            data-key="t-transactions">
                                                            {{ $t("t-transactions") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/buy-sell" class="nav-link"
                                                            data-key="t-buy-sell">
                                                            {{ $t("t-buy-sell") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/orders" class="nav-link"
                                                            data-key="t-orders">
                                                            {{ $t("t-orders") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/wallet" class="nav-link"
                                                            data-key="t-my-wallet">
                                                            {{ $t("t-my-wallet") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/ico" class="nav-link"
                                                            data-key="t-ico-list">
                                                            {{ $t("t-ico-list") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/crypto/kyc" class="nav-link"
                                                            data-key="t-kyc-application">
                                                            {{ $t("t-kyc-application") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarInvoices" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarInvoices"
                                                data-key="t-invoices">
                                                {{ $t("t-invoices") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarInvoices">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/invoices/list" class="nav-link"
                                                            data-key="t-list-view">
                                                            {{ $t("t-list-view") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/invoices/detail" class="nav-link"
                                                            data-key="t-details">
                                                            {{ $t("t-details") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/invoices/create" class="nav-link"
                                                            data-key="t-create-invoice">
                                                            {{ $t("t-create-invoice") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#sidebarTickets" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarTickets"
                                                data-key="t-supprt-tickets">
                                                {{ $t("t-supprt-tickets") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarTickets">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/apps/tickets-list" class="nav-link"
                                                            data-key="t-list-view">
                                                            {{ $t("t-list-view") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/tickets-details" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-ticket-details") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <b-link href="#nftmarketplace" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="nftmarketplace"
                                                data-key="t-supprt-tickets">
                                                {{ $t("t-nft-marketplace") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="nftmarketplace">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-marketplace" class="nav-link"
                                                            data-key="t-list-view">
                                                            {{ $t("t-marketplace") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-explore" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-explore-now") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-auction" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-live-auction") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-item-detail" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-item-details") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-collection" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-collections") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-creators" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-creators") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-ranking" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-ranking") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-wallet" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-wallet-connect") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/apps/nft-create" class="nav-link"
                                                            data-key="t-ticket-details">
                                                            {{ $t("t-create-nft") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/apps-file-manager" class="nav-link"
                                                data-key="t-file-manager">
                                                {{ $t("t-file-manager") }}
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/apps-todo" class="nav-link" data-key="t-to-do">
                                                {{ $t("t-to-do") }}
                                            </router-link>
                                        </li>

                                        <li class="nav-item">
                                            <b-link href="#sidebarjobs" class="nav-link" data-bs-toggle="collapse"
                                                role="button" aria-expanded="false" aria-controls="sidebarjobs"
                                                data-key="t-jobs">
                                                {{ $t("t-jobs") }}
                                            </b-link>
                                            <div class="collapse menu-dropdown" id="sidebarjobs">
                                                <ul class="nav nav-sm flex-column">
                                                    <li class="nav-item">
                                                        <router-link to="/jobs/statistics" class="nav-link"
                                                            data-key="t-statistics">
                                                            {{ $t("t-statistics") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <b-link href="#sidebarjoblists" class="nav-link"
                                                            data-bs-toggle="collapse" role="button"
                                                            aria-expanded="false" aria-controls="sidebarjoblists"
                                                            data-key="t-projects">
                                                            {{ $t("t-job-lists") }}
                                                        </b-link>
                                                        <div class="collapse menu-dropdown" id="sidebarjoblists">
                                                            <ul class="nav nav-sm flex-column">
                                                                <li class="nav-item">
                                                                    <router-link to="/jobs/lists" class="nav-link"
                                                                        data-key="t-list">
                                                                        {{ $t("t-list") }}
                                                                    </router-link>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <router-link to="/jobs/grid-lists" class="nav-link"
                                                                        data-key="t-grid">
                                                                        {{ $t("t-grid") }}
                                                                    </router-link>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <router-link to="/jobs/details" class="nav-link"
                                                                        data-key="t-overview">
                                                                        {{ $t("t-overview") }}
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li class="nav-item">
                                                        <b-link href="#sidebarcandidatelists" class="nav-link"
                                                            data-bs-toggle="collapse" role="button"
                                                            aria-expanded="false" aria-controls="sidebarcandidatelists"
                                                            data-key="t-projects">
                                                            {{ $t("t-candidate-lists") }}
                                                        </b-link>
                                                        <div class="collapse menu-dropdown" id="sidebarcandidatelists">
                                                            <ul class="nav nav-sm flex-column">
                                                                <li class="nav-item">
                                                                    <router-link to="/jobs/candidate-lists"
                                                                        class="nav-link" data-key="t-list">
                                                                        {{ $t("t-list-view") }}
                                                                    </router-link>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <router-link to="/jobs/candidate-grid"
                                                                        class="nav-link" data-key="t-grid">
                                                                        {{ $t("t-grid-view") }}
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/jobs/application" class="nav-link"
                                                            data-key="t-application">
                                                            {{ $t("t-application") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/jobs/new" class="nav-link" data-key="t-new">
                                                            {{ $t("t-new-job") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/jobs/companies-list" class="nav-link"
                                                            data-key="t-companies-list">
                                                            {{ $t("t-companies-list") }}
                                                        </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link to="/jobs/categories" class="nav-link"
                                                            data-key="t-categories">
                                                            {{ $t("t-job-categories") }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li class="nav-item">
                                            <router-link to="/apps-api-key" class="nav-link" data-key="t-api-key">
                                                {{ $t("t-api-key") }}
                                            </router-link>
                                        </li>

                                    </ul>
                                </div>
                            </li> -->
                        </ul>
                    </b-container>
                    <!-- Sidebar -->
                </div>
                <!-- Left Sidebar End -->
                <!-- Vertical Overlay-->
                <div class="vertical-overlay"></div>
            </div>
            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->
            <div v-if="mobile">
                <div class="">
                    <div class="main-frame">
                        <slot />
                    </div>
                    <CustomFooter/>
                </div>
            </div>
            <div class="main-content" v-else>
                <div class="page-content">
                    <b-container fluid>
                        <slot />
                    </b-container>
                </div>
                <Footer />
            </div>
            <RightBar />
        </div>
    </div>
</template>