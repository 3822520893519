import { handleCallApi } from "@/helpers/api-method";
import UserService from "@/services/user.service";
export const state = {
    users: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_USERS(state, value) {
        state.users = value;
    }
};

export const actions = {
    async getUsers({ commit }, query) {
        await handleCallApi(() => UserService.getUsers(query), (value) => commit('CHANGE_USERS', value));
    }
};