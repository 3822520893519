import { handleCallApi } from "@/helpers/api-method";
import CategoryAssistantService from '@/services/category-assistant.service';
export const state = {

    categoryAssistant: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_CATEGORY_ASSISTANT(state, value) {
        state.categoryAssistant = value
    },

};

export const actions = {
    async getCategoryAssistant({ commit }) {
        handleCallApi(CategoryAssistantService.getCategoryAssistant, (value) => commit('CHANGE_CATEGORY_ASSISTANT', value));
    },
    
};