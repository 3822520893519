import { API_MEMBERS, API_UPDATE_MEMBER } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Member {

    async getMembers(query) {
        const result = await Fetch.make().get(API_MEMBERS, {authHeader: true, query})
        return result
    }

    async updateMember(data) {
        const url = API_UPDATE_MEMBER.replace(":id", data.uuid)
        const result = await Fetch.make().put(url, data, {authHeader: true})
        return result
    }
}

export default new Member();