import {
    API_CREATE_PLANNING,
    API_GET_PLANNING_DETAIL,
    API_GET_LIST_PLAN,
    API_UPDATE_PLAN,
    API_DETELE_PLAN,
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class planningService {
    async createPlanning(payload) {
        const result = await Fetch.make().post(API_CREATE_PLANNING, payload, {
            authHeader: true,
        });
        return result;
    }

    async getPlanningDetail(id) {
        const url = API_GET_PLANNING_DETAIL.replace(":id", id);
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }

    async getPlannings(query) {
        const result = await Fetch.make().get(API_GET_LIST_PLAN, {
            authHeader: true,
            query,
        });
        return result;
    }

    async updatePlanning(payload) {
        const url = API_UPDATE_PLAN.replace(":id", payload.id);
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async deletePlanning(id) {
        const url = API_DETELE_PLAN.replace(":id", id);
        const result = await Fetch.make().delete(url, { authHeader: true });
        return result;
    }
}

export default new planningService();
