<script>
import router from "@/router";
import {
    SimpleBar
} from "simplebar-vue3";
import {
    layoutComputed
} from "@/state/helpers";
import Menu from "@/components/menu.vue";
import NavBar from "@/components/nav-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";
import { sidebar_routes } from "@/config/sidebar-route";
import isEmpty from "lodash/isEmpty";
import get from 'lodash/get'
import {ScreenHelper} from "@/helpers/utils";
import CustomFooter from "@/components/ui/footer/custom-footer.vue";

/**
 * Vertical layout
 */
export default {
    components: {
        NavBar,
        RightBar,
        Footer,
        SimpleBar,
        Menu,
        CustomFooter
    },
    data() {
        return {
            isMenuCondensed: false,
            rmenu: localStorage.getItem('rmenu') ? localStorage.getItem('rmenu') : 'twocolumn',
            sidebar_routes,
            mobile: false,
            permissions: this.$store.state.auth.permissions.data
        };
    },

    computed: {
        ...layoutComputed,
    },
    created: () => {
        document.body.removeAttribute("data-layout", "horizontal");
        document.body.removeAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-layout-size", "boxed");
    },
    methods: {
        _get(obj, path, defaultValue = '') {
            return get(obj, path, defaultValue);
        },
        _isEmpty(obj) {
            return isEmpty(obj);
        },
        initActiveMenu() {
            const pathName = window.location.pathname;
            const ul = document.getElementById("navbar-nav");
            if (ul) {
                const items = Array.from(ul.querySelectorAll("a.nav-link"));
                let activeItems = items.filter((x) => x.classList.contains("active"));
                this.removeActivation(activeItems);
                let matchingMenuItem = items.find((x) => {
                    return x.getAttribute("href") === pathName;
                });
                if (matchingMenuItem) {
                    this.activateParentDropdown(matchingMenuItem);
                } else {
                    var id = pathName.replace("/", "");
                    if (id) document.body.classList.add("twocolumn-panel");
                    this.activateIconSidebarActive(pathName);
                }
            }
        },

        updateMenu(e) {
            document.body.classList.remove("twocolumn-panel");
            const ul = document.getElementById("navbar-nav");
            if (ul) {
                const items = Array.from(ul.querySelectorAll(".show"));
                items.forEach((item) => {
                    item.classList.remove("show");
                });
            }
            // const icons = document.getElementById("two-column-menu");
            // if (icons) {
            //     const activeIcons = Array.from(
            //         icons.querySelectorAll(".nav-icon.active")
            //     );
            //     activeIcons.forEach((item) => {
            //         item.classList.remove("active");
            //     });
            // }
            document.getElementById(e).classList.add("show");
            // this.activateIconSidebarActive("#" + e);
        },

        removeActivation(items) {
            items.forEach((item) => {
                if (item.classList.contains("menu-link")) {
                    if (!item.classList.contains("active")) {
                        item.setAttribute("aria-expanded", false);
                    }
                    item.nextElementSibling.classList.remove("show");
                }
                if (item.classList.contains("nav-link")) {
                    if (item.nextElementSibling) {
                        item.nextElementSibling.classList.remove("show");
                    }
                    item.setAttribute("aria-expanded", false);
                }
                item.classList.remove("active");
            });
        },

        activateIconSidebarActive(id) {
            var menu = document.querySelector(
                "#two-column-menu .simplebar-content-wrapper a[href='" +
                id +
                "'].nav-icon"
            );
            if (menu !== null) {
                menu.classList.add("active");
            }
        },

        activateParentDropdown(item) {
            // navbar-nav menu add active
            item.classList.add("active");
            let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
                // to set aria expand true remaining
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                    if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling) {
                        if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling.parentElement.closest(".collapse.menu-dropdown")) {
                            const grandparent = parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown").previousElementSibling.parentElement.closest(".collapse.menu-dropdown");
                            this.activateIconSidebarActive("#" + grandparent.getAttribute("id"));
                            grandparent.classList.add("show");
                        }
                    }
                    this.activateIconSidebarActive("#" + parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")
                        .getAttribute("id"));

                    parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                    if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                        parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                    return false;
                }
                this.activateIconSidebarActive("#" + parentCollapseDiv.getAttribute("id"));
                return false;
            }
            return false;
        },

        toggleMenu() {
            document.body.classList.toggle("sidebar-enable");

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                    document.body.classList.remove("vertical-collpsed");
                });
                document.body.classList.toggle("vertical-collpsed");
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                });
                document.body.classList.remove("vertical-collpsed");
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },

        toggleRightSidebar() {
            document.body.classList.toggle("right-bar-enabled");
        },

        hideRightSidebar() {
            document.body.classList.remove("right-bar-enabled");
        },
    },

    mounted() {
        ScreenHelper.initialize((isMobile) => {
            this.mobile = isMobile
        })
        this.initActiveMenu();
        if (this.rmenu == 'vertical' && this.layoutType == 'twocolumn') {
            document.documentElement.setAttribute("data-layout", "vertical");
        }
        document.getElementById('overlay').addEventListener('click', () => {
            document.body.classList.remove('vertical-sidebar-enable');
        });

        window.addEventListener("resize", () => {
            if (this.layoutType == 'twocolumn') {
                var windowSize = document.documentElement.clientWidth;
                if (windowSize < 767) {
                    document.documentElement.setAttribute("data-layout", "vertical");
                    this.rmenu = 'vertical';
                    localStorage.setItem('rmenu', 'vertical');
                } else {
                    document.documentElement.setAttribute("data-layout", "twocolumn");
                    this.rmenu = 'twocolumn';
                    localStorage.setItem('rmenu', 'twocolumn');
                    setTimeout(() => {
                        this.initActiveMenu();
                    }, 50);

                }
            }
        });
    },
};
</script>

<template>
    <div id="layout-wrapper">
        <NavBar />
        <div>
            <!-- ========== Left Sidebar Start ========== -->
            <!-- ========== App Menu ========== -->
            <div class="app-menu navbar-menu">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <!-- Dark Logo-->
                    <router-link to="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-dark.png" alt="" height="17" />
                        </span>
                    </router-link>
                    <!-- Light Logo-->
                    <router-link to="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="@/assets/images/logo-light.png" alt="" height="17" />
                        </span>
                    </router-link>
                    <button type="button" class=" btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i class="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar" v-if="rmenu == 'twocolumn'">
                    <b-container fluid>
                        <div id="two-column-menu">
                            <SimpleBar class="twocolumn-iconview list-unstyled">
                                <b-link href="#" class="logo"><img src="@/assets/images/logo-sm.png" alt="Logo"
                                        height="22" /></b-link>
                                <li v-for="(item, index) in sidebar_routes" :key="index"
                                    :class="{
                                        'd-none': _isEmpty(_get(permissions, item.permission)) && _get(item, 'permission') !== 'public',
                                        'nav-item': _isEmpty(item.childrens)
                                    }"    
                                >
                                    <b-link v-if="!_isEmpty(item.childrens)" class="nav-icon" :href="`#${item.id}`"
                                        role="button" 
                                        :class="$route.fullPath.includes(item.name) ? 'active' : ''"
                                        @click.prevent="updateMenu(item.name)"
                                        v-b-tooltip.hover.right="{ title: item.label }"
                                        >
                                        <i :class="item.icon"></i>
                                    </b-link>
                                    <router-link v-if="_isEmpty(item.childrens)" :to="item.link" class="nav-icon"
                                        v-b-tooltip.hover.right="{ title: item.label }"
                                            :class="$route.fullPath.includes(item.name) ? 'active' : ''"
                                        >
                                        <i :class="item.icon"></i>
                                    </router-link>
                                </li>

                            </SimpleBar>
                        </div>
                        <template v-if="layoutType === 'twocolumn'">
                            <SimpleBar class="navbar-nav" id="navbar-nav">
                                <li class="menu-title">
                                    <span data-key="t-menu"> {{ $t("t-menu") }}</span>
                                </li>
                                <li v-for="(item, index) in sidebar_routes" :key="index" class="nav-item"
                                >
                                    <div class="collapse menu-dropdown" :id="item.id" v-if="!_isEmpty(item.childrens)">
                                        <ul class="nav nav-sm flex-column">
                                            <li v-for="(child, index) in item.childrens" :key="index" class="nav-item"
                                                :class="{
                                                    'd-none': _isEmpty(_get(permissions, child.permission)) && _get(child, 'permission') !== 'public',
                                                }"    
                                            >
                                                <router-link :to="child.link" class="nav-link"
                                                    :class="$route.fullPath == child.link ? 'active' : ''"
                                                >
                                                    {{ child.label }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                            </SimpleBar>
                        </template>
                    </b-container>
                </div>

                <SimpleBar id="scrollbar" class="h-100" ref="scrollbar" v-if="rmenu == 'vertical'">
                    <Menu></Menu>
                </SimpleBar>

                <div class="sidebar-background"></div>
            </div>
            <!-- Left Sidebar End -->
            <!-- Vertical Overlay-->
            <div class="vertical-overlay" id="overlay"></div>
        </div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div v-if="mobile">
            <div class="">
                <div class="main-frame">
                    <slot />
                </div>
                <CustomFooter />
            </div>
        </div>
        <div class="main-content" v-else>
            <div class="page-content">
                <b-container fluid>
                    <slot />
                </b-container>
            </div>
            <Footer />
        </div>
        <RightBar />
    </div>
</template>