import { API_GET_PROFILE, API_UPDATE_PROFILE } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Profile {

    async getProfile() {
        const result = await Fetch.make().get(API_GET_PROFILE, {authHeader: true})
        return result
    }

    async updateProfile(payload) {
        const result = await Fetch.make().put(API_UPDATE_PROFILE, payload, {authHeader: true})
        return result
    }
}

export default new Profile();