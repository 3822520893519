<script>
import get from 'lodash/get'
import Main from './layouts/main.vue';
import Auth from './layouts/auth.vue';
import None from './layouts/none.vue';
export default {
    name: 'App',
    data() {
        return {
            Component: {
                'main': Main,
                'auth': Auth,
                'none': None
            },
            get,
        }
    },
    components: {
        Main,
    }
}
</script>

<template>
    <component :is="get(Component, this.$route.meta.layout, 'none')">
        <router-view></router-view>
    </component>
</template>
