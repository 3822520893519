import { API_CONTRACTS } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class ContractService {
    async getContracts(query) {
        const result = await Fetch.make().get(API_CONTRACTS, {authHeader: true, query})
        return result
    }

    async getContract(id) {
        const url = `${API_CONTRACTS}/${id}`
        const result = await Fetch.make().get(url, {authHeader: true})
        return result
    }
    
}

export default new ContractService();