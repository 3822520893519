export default {
    TODO: {
        value: 0,
        label: "Chưa thực hiện",
        badge: "badge badge-primary",
        variant: "primary", // Thêm giá trị này
    },
    IN_PROGRESS: {
        value: 1,
        label: "Đang thực hiện",
        badge: "badge badge-info",
        variant: "info", // Thêm giá trị này
    },
    REQUEST_REVIEW: {
        value: 2,
        label: "Chờ xem xét",
        badge: "badge badge-secondary",
        variant: "secondary", // Thêm giá trị này
    },
    REVIEW_PROBLEM: {
        value: 3,
        label: "Admin phản hồi",
        badge: "badge badge-danger",
        variant: "danger", // Thêm giá trị này
    },
    REQUEST_APPROVE: {
        value: 4,
        label: "Chờ khách hàng phê duyệt",
        badge: "badge badge-secondary",
        variant: "secondary", // Thêm giá trị này
    },
    APPROVE_PROBLEM: {
        label: "Khách hàng phản hồi",
        value: 5,
        badge: "badge badge-danger",
        variant: "danger", // Thêm giá trị này
    },
    APPROVED: {
        value: 6,
        label: "Đã duyệt",
        badge: "badge badge-warning",
        variant: "warning", // Thêm giá trị này
    },
    PUBLISHED: {
        value: 8,
        label: "Xuất bản",
        badge: "badge badge-success",
        variant: "success", 
    },
    SCHEDULE_PUBLISHED: {
        value: 7,
        label: "Đã lên lịch",
        badge: "badge badge-dark",
        variant: "dark", 
    },
};
