import { handleCallApi } from "@/helpers/api-method";
import RoleService from "@/services/role.service";
export const state = {
    roles: {
        status: '',
        data: [],
        message: '',
    },
    create: {
        status: '',
        data: [],
        message: '',
    },
    update: {
        status: '',
        data: [],
        message: '',
    },
    delete: {
        status: '',
        data: [],
        message: '',
    },
    roleDetail: {
        status: '',
        data: [],
        message: '',
    }
    
};

export const mutations = {
    CHANGE_ROLES(state, value) {
        state.roles = value;
    },
    CHANGE_CREATE(state, value) {
        state.create = value;
    },
    CHANGE_UPDATE(state, value) {
        state.update = value;
    },
    CHANGE_DELETE(state, value) {
        state.delete = value;
    },
    CHANGE_ROLE_DETAIL(state, value) {
        state.roleDetail = value;
    }
};

export const actions = {
    async getRoles({ commit }, query) {
        await handleCallApi(() => RoleService.getRoles(query), (value) => commit('CHANGE_ROLES', value));
    },
    async createRole({ commit }, data) {
        await handleCallApi(() => RoleService.createRole(data), (value) => commit('CHANGE_CREATE', value));
    },
    async updateRole({ commit }, data) {
        await handleCallApi(() => RoleService.updateRole(data), (value) => commit('CHANGE_UPDATE', value));
    },
    async deleteRole({ commit }, data) {
        await handleCallApi(() => RoleService.deleteRole(data), (value) => commit('CHANGE_DELETE', value));
    },
    async getRoleDetail({ commit }, data) {
        await handleCallApi(() => RoleService.getRoleDetail(data), (value) => commit('CHANGE_ROLE_DETAIL', value));
    }
};